@font-face {
    font-family: 'NordsternRegular';
    src: local('NordsternRegular'), url(components/_fonts/Nordstern-Normal.ttf) format('truetype');
}
@font-face {
    font-family: 'NordsternBold';
    src: local('NordsternBold'), url(components/_fonts/Nordstern-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'DINProRegular';
    src: local('DINProRegular'), url(components/_fonts/DINPro-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'DINProBold';
    src: local('DINProBold'), url(components/_fonts/DINPro-Bold.ttf) format('truetype');
}
